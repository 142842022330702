import { APIDataCollection, ProductMarketing } from 'react-morel-auth';
import { QGApi } from '../reducers/QGApi';

export const extendedApi = QGApi.injectEndpoints({
  endpoints: builder => ({
    getMarketingFields: builder.query<Array<ProductMarketing>, { productId: string; params: string }>({
      query: ({ productId, params }) => ({
        url: `/catalog/products/${productId}/marketings`,
        method: 'GET',
        params,
      }),
      transformResponse: (response: APIDataCollection<ProductMarketing>, meta, arg) => {
        return response['hydra:member'];
      },
      providesTags: result =>
        // is result available?
        result
          ? // successful query
            [
              ...result.map(({ id }) => ({ type: 'MarketingFields', id } as const)),
              { type: 'MarketingFields', id: 'LIST' },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
            [{ type: 'MarketingFields', id: 'LIST' }],
    }),
    addMarketingField: builder.mutation<ProductMarketing, ProductMarketing>({
      query(body) {
        return {
          url: `/catalog/products/${body.productId}/marketings`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'MarketingFields', id: 'LIST' }],
    }),
    updateMarketingField: builder.mutation<
      ProductMarketing,
      Partial<ProductMarketing> & Pick<ProductMarketing, 'productId'> & { previousId: string }
    >({
      query({ previousId, ...body }) {
        return {
          url: `/catalog/products/${body.productId}/marketings/${previousId}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [{ type: 'MarketingFields', id: 'LIST' }],

      // invalidatesTags: (result, error, { id }) => [{ type: 'MarketingFields', id }],
    }),
    deleteMarketingField: builder.mutation<
      { success: boolean; id: number },
      Pick<ProductMarketing, 'productId'> & { previousId: string }
    >({
      query(arg) {
        return {
          url: `/catalog/products/${arg.productId}/marketings/${arg.previousId}`,
          method: 'DELETE',
        };
      },
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: (result, error, { previousId }) => [{ type: 'MarketingFields', previousId }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMarketingFieldsQuery,
  useAddMarketingFieldMutation,
  useUpdateMarketingFieldMutation,
  useDeleteMarketingFieldMutation,
} = extendedApi;
