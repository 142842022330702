import { PaletteColor, PaletteColorOptions, PaletteMode } from '@mui/material';
import { createTheme } from '@mui/material/styles';

export const fullDrawerWidth = 240;
export const smallDrawerWidth = 60;
export const closeDrawerWidth = 0;
export const appBarHeight = 60;

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#ff6659',
      main: '#d32f2f',
      dark: '#9a0007',
      contrastText: '#fff',
    },
    background: {
      paper: '#1A1A1A',
    },
  },
  typography: {
    fontFamily: `"Fira Sans", sans-serif`,
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#1A1A1A',
          borderRadius: 5,
        },
      },
    },
  },
});

export const lightTheme = createTheme({
  // spacing: [0, 4, 8, 16, 32, 64, 128, 246],
  palette: {
    mode: 'light',
    primary: {
      light: '#ff6659',
      main: '#d32f2f',
      dark: '#9a0007',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffffff',
      main: '#dcdcdc',
      dark: '#aaaaaa',
      contrastText: '#000',
    },
    text: {
      // primary: '#000',
      // secondary: '#FFF',
    },
    background: {
      default: '#fff',
    },
    action: {
      // active: string;
      hover: '#ffe5e6',
      // hoverOpacity: number;
      selected: '#ff6659',
      // selectedOpacity: number;
      // disabled: string;
      // disabledOpacity: number;
      // disabledBackground: string;
      // focus: string;
      // focusOpacity: number;
      // activatedOpacity: number;
    },
    error: {
      light: '#D18676',
      main: '#D84222',
      dark: '#9F2D15',
    },
    warning: {
      light: '#df9862',
      main: '#d87f3b',
      dark: '#975829',
    },
  },
  typography: {
    fontFamily: `"Fira Sans", sans-serif`,
  },
});
