import React from 'react';
import Loader from './Loader';
import { styled } from '@mui/material/styles';

type LoadingFallbackProps = {};

const StyledContainer = styled('div')`
  width: 100%;
  height: 100vh;
  margin: auto;
`;

const LoadingFallback: React.FC<LoadingFallbackProps> = () => {
  return <StyledContainer />;
};

export default LoadingFallback;
