import { configureStore } from '@reduxjs/toolkit';
import productMarketingFieldsReducer from './productMarketingFieldsStore';
import { useSelector } from 'react-redux';
import productParamsReducer from '../network/utils/reducer/productParamsReducer';
import productMarketingReducer from './slices/productMarketingSlice';
import { QGApi } from '../network/QGApi/reducers/QGApi';

export const rootStore = configureStore({
  reducer: {
    productMarketingFields: productMarketingFieldsReducer,
    productParams: productParamsReducer,
    [QGApi.reducerPath]: QGApi.reducer,
    productMarketing: productMarketingReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(QGApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootStoreState = ReturnType<typeof rootStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type RootStoreDispatch = typeof rootStore.dispatch;

export const useProductMarketingFieldsState = () => {
  return useSelector((state: RootStoreState) => state.productMarketingFields);
};

export const useProductsState = () => {
  return useSelector((state: RootStoreState) => state.productParams);
};

export const useProductMarketingState = () => {
  return useSelector((state: RootStoreState) => state.productMarketing);
};
