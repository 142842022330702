import React, { lazy } from 'react';
import { Navigate, Route, Routes as Router } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';
import NavigationLayout from '../component/NavigationLayout';
import LoadingFallback from '../component/LoadingFallback';
import { routesURL } from './routesURL';
import Jobs from '../modules/Jobs';

const HomeScreen = lazy(() => import('../screen/HomeScreen'));
const LoginScreen = lazy(() => import('../screen/LoginScreen'));
const PartnerUsersScreen = lazy(() => import('../modules/PartnerUsers/PartnerUsersListScreen'));
const PartnerUserDetailScreen = lazy(() => import('../modules/PartnerUsers/PartnerUserDetailScreen'));
const PartnerUserSynchroScreen = lazy(
  () => import('../modules/PartnerUsers/PartnerUserDetailScreen/PartnerUserSynchro'),
);
const PartnerUserProductsScreen = lazy(
  () => import('../modules/PartnerUsers/PartnerUserDetailScreen/PartnerUserProducts'),
);
const PartnerUserInformationScreen = lazy(
  () => import('../modules/PartnerUsers/PartnerUserDetailScreen/PartnerUserInformation'),
);
const PartnerUserCreateScreen = lazy(() => import('../modules/PartnerUsers/CreatePartnerUserScreen'));

const UsersScreen = lazy(() => import('../modules/AppUsers/AppUsersListScreen'));
const UserDetailScreen = lazy(() => import('../modules/AppUsers/AppUserDetailScreen'));
const UserCreateScreen = lazy(() => import('../modules/AppUsers/CreateAppUserScreen'));

const AppsScreen = lazy(() => import('../modules/Apps/AppsListScreen'));
const AppDetailScreen = lazy(() => import('../modules/Apps/AppDetailScreen'));
const AppCreateScreen = lazy(() => import('../modules/Apps/CreateAppScreen'));

const PartnersScreen = lazy(() => import('../modules/Partners/PartnersListScreen'));
const PartnerDetailScreen = lazy(() => import('../modules/Partners/PartnerDetailScreen'));
const PartnerCreateScreen = lazy(() => import('../modules/Partners/CreatePartnerScreen'));

const PartnerServiceDetailScreen = lazy(() => import('../modules/PartnerService/PartnerServiceDetail'));
const PartnerServiceScreen = lazy(() => import('../modules/PartnerService/PartnerServicesListScreen'));
const PartnerServiceCreateScreen = lazy(() => import('../modules/PartnerService/CreatePartnerServiceScreen'));

const ServiceScreen = lazy(() => import('../modules/Services/ServicesListScreen'));

const HostsScreen = lazy(() => import('../modules/Hosts/HostsListScreen'));
const HostCreateScreen = lazy(() => import('../modules/Hosts/CreateHostScreen'));
const HostDetailScreen = lazy(() => import('../modules/Hosts/HostDetailScreen'));

const UserServicesScreen = lazy(() => import('../modules/UserService/UserServicesListScreen'));
const UserServicesDetailScreen = lazy(() => import('../modules/UserService/UserServiceDetail'));
const UserServicesCreateScreen = lazy(() => import('../modules/UserService/CreateUserServiceScreen'));

const ProductsPage = lazy(() => import('../modules/Products/ProductsPage'));
const MarketingProductsPage = lazy(() => import('../modules/MarketingProducts'));
const MarketingProductDetailPage = lazy(() => import('../modules/MarketingProducts/MarketingProductDetail'));
const DynamicMessagePage = lazy(() => import('../modules/DynamicMessage'));
const JobsPage = lazy(() => import('../modules/Jobs'));

const Routes = () => {
  return (
    <Router>
      <Route
        path={routesURL.login}
        element={
          <React.Suspense fallback={<LoadingFallback />}>
            <LoginScreen />
          </React.Suspense>
        }
      />
      <Route element={<NavigationLayout />}>
        <Route
          path={routesURL.dashboard}
          element={
            <ProtectedRoutes>
              <HomeScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.partnerUsers}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_PARTNER_USER_READ']}>
              <React.Suspense fallback={<LoadingFallback />}>
                <PartnerUsersScreen />
              </React.Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.createPartnerUser}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_PARTNER_USER_CREATE']}>
              <PartnerUserCreateScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.partnerUserDetail + '/:id/' + 'commercial-products'}
          element={
            <ProtectedRoutes requiredRoles={[]}>
              <PartnerUserProductsScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.partnerUserDetail + '/:id/' + 'informations'}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_PARTNER_USER_READ']}>
              <PartnerUserInformationScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.partnerUserDetail + '/:id/' + 'synchro'}
          element={
            <ProtectedRoutes requiredRoles={[]}>
              <PartnerUserSynchroScreen />
            </ProtectedRoutes>
          }
        />

        <Route
          path={routesURL.partnerUserDetail + '/:id'}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_PARTNER_USER_READ']}>
              <PartnerUserDetailScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.apps}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_APPLICATION_READ']}>
              <AppsScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.createApp}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_APPLICATION_CREATE']}>
              <AppCreateScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.appDetail + '/:id'}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_APPLICATION_READ']}>
              <AppDetailScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.appUsers}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_USER_READ']}>
              <UsersScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.createAppUser}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_USER_CREATE']}>
              <UserCreateScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.appUserDetail + '/:id'}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_USER_READ']}>
              <UserDetailScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.partnerServicesDetail + '/:id'}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_PARTNER_SERVICE_READ']}>
              <PartnerServiceDetailScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.createPartnerServices}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_PARTNER_SERVICE_CREATE']}>
              <PartnerServiceCreateScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.partnerServices}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_PARTNER_SERVICE_READ']}>
              <PartnerServiceScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.partners}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_PARTNER_READ']}>
              <PartnersScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.createPartner}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_PARTNER_CREATE']}>
              <PartnerCreateScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.partnerDetail + '/:id'}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_PARTNER_READ']}>
              <PartnerDetailScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.services}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_SERVICE_READ']}>
              <ServiceScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.hosts}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_HOST_READ']}>
              <HostsScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.createHost}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_HOST_CREATE']}>
              <HostCreateScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.products}
          element={
            <ProtectedRoutes requiredRoles={[]}>
              <ProductsPage />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.hostDetail + '/:id'}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_HOST_READ']}>
              <HostDetailScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.userServices}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_USER_SERVICE_READ']}>
              <UserServicesScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.userServiceDetail + '/:id'}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_USER_SERVICE_READ']}>
              <UserServicesDetailScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.jobs}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_USER_SERVICE_READ']}>
              <JobsPage />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.createUsersService}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_USER_SERVICE_CREATE']}>
              <UserServicesCreateScreen />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.marketingProducts}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_SDB_PRODUCT_READ']}>
              <MarketingProductsPage />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.dynamicMessage}
          element={
            <ProtectedRoutes requiredRoles={['ROLE_AUTH_PARTNER_SERVICE_READ']}>
              <DynamicMessagePage />
            </ProtectedRoutes>
          }
        />
        <Route
          path={routesURL.marketingProducts + '/:id'}
          element={
            <ProtectedRoutes
              requiredRoles={[
                'ROLE_SDB_PRODUCT_MARKETING_UPDATE',
                'ROLE_SDB_PRODUCT_MARKETING_CREATE',
                'ROLE_SDB_PRODUCT_MARKETING_DELETE',
              ]}
            >
              <MarketingProductDetailPage />
            </ProtectedRoutes>
          }
        />
        <Route path={'*'} element={<Navigate to={routesURL.dashboard} />} />
      </Route>
    </Router>
  );
};

export default Routes;
