import React from 'react';
import {
  Group,
  Home,
  Sync,
  Badge,
  Apps,
  Business,
  DomainDisabled,
  Router,
  FormatListBulleted,
  Task,
  Chat,
} from '@mui/icons-material';
import { userPermissions } from '../utils/userPermissions';
import { routesURL } from './routesURL';

export type EntryType = 'collapse' | 'item' | 'groupHeader';

type NavigationConfig = Array<NavigationEntry>;

export type NavigationEntry = {
  id?: string;
  title?: string;
  type: EntryType;
  icon?: React.ReactNode;
  permissions?: Array<string>;
  navLink?: string;
  groupTitle?: string;
  children?: Array<NavigationEntry>;
  group: string;
};

export const navigationConfig: NavigationConfig = [
  {
    id: 'dashboard',
    title: 'Home',
    type: 'item',
    icon: <Home />,
    navLink: routesURL.dashboard,
    group: '',
  },
  {
    type: 'groupHeader',
    title: 'System',
    group: 'system',
  },
  {
    id: 'apps',
    title: 'Apps',
    type: 'item',
    icon: <Apps />,
    permissions: ['ROLE_AUTH_APPLICATION_READ', '*ROLE_AUTH_APPLICATION_READ'],
    navLink: routesURL.apps,
    group: 'system',
  },
  {
    id: 'services',
    title: 'Services',
    type: 'item',
    permissions: ['ROLE_AUTH_SERVICE_READ', '*ROLE_AUTH_SERVICE_READ'],
    icon: <DomainDisabled />,
    navLink: routesURL.services,
    group: 'system',
  },
  {
    id: 'hosts',
    title: 'Hosts',
    type: 'item',
    permissions: ['ROLE_AUTH_HOST_READ', '*ROLE_AUTH_HOST_READ'],
    icon: <Router />,
    navLink: routesURL.hosts,
    group: 'system',
  },
  {
    type: 'groupHeader',
    title: 'User',
    group: 'user',
  },
  {
    id: 'app_users',
    title: 'Users',
    type: 'item',
    icon: <Badge />,
    permissions: ['ROLE_AUTH_USER_READ', '*ROLE_AUTH_USER_READ'],
    navLink: routesURL.appUsers,
    group: 'user',
  },
  {
    id: 'user_services',
    title: 'Users Services',
    type: 'item',
    icon: <DomainDisabled />,
    permissions: ['ROLE_AUTH_USER_SERVICE_READ', '*ROLE_AUTH_USER_SERVICE_READ'],
    navLink: routesURL.userServices,
    group: 'user',
  },
  {
    type: 'groupHeader',
    title: 'Partner',
    group: 'partner',
  },
  {
    id: 'partner_users',
    title: 'Users',
    type: 'item',
    icon: <Group />,
    permissions: ['ROLE_AUTH_PARTNER_USER_READ', '*ROLE_AUTH_PARTNER_USER_READ'],
    navLink: routesURL.partnerUsers,
    group: 'partner',
  },
  {
    id: 'partnersList',
    title: 'Partners',
    type: 'item',
    icon: <Business />,
    permissions: ['ROLE_AUTH_PARTNER_READ', '*ROLE_AUTH_PARTNER_READ'],
    navLink: routesURL.partners,
    group: 'partner',
  },
  {
    id: 'partnerServices',
    title: 'Services',
    type: 'item',
    permissions: ['ROLE_AUTH_PARTNER_SERVICE_READ', '*ROLE_AUTH_PARTNER_SERVICE_READ'],
    icon: <DomainDisabled />,
    navLink: routesURL.partnerServices,
    group: 'partner',
  },
  {
    type: 'groupHeader',
    title: 'Products',
    group: 'product',
  },
  {
    id: 'products',
    title: 'Products',
    type: 'item',
    icon: <FormatListBulleted />,
    permissions: [],
    navLink: routesURL.products,
    group: 'product',
  },
  {
    type: 'groupHeader',
    title: 'Scheduler',
    group: 'scheduler',
  },
  {
    id: 'jobs',
    title: 'Jobs',
    type: 'item',
    icon: <Task />,
    permissions: ['ROLE_SCHEDULER_JOB_READ', '*ROLE_SCHEDULER_JOB_READ'],
    navLink: routesURL.jobs,
    group: 'scheduler',
  },
  {
    type: 'groupHeader',
    title: 'Marketing',
    group: 'marketing',
  },
  {
    id: 'dynamicMessage',
    title: 'Dynamic Message',
    type: 'item',
    icon: <Chat />,
    permissions: ['ROLE_AUTH_PARTNER_SERVICE_READ'],
    navLink: routesURL.dynamicMessage,
    group: 'marketing',
  },
  {
    id: 'products',
    title: 'Products',
    type: 'item',
    icon: <FormatListBulleted />,
    permissions: [],
    navLink: routesURL.marketingProducts,
    group: 'marketing',
  },

  // {
  //   id: 'todo',
  //   title: 'Todo',
  //   type: 'item',
  //   icon: <Storefront />,
  //   permissions: ['admin', 'editor'],
  //   navLink: '/login',
  // },
  // {
  //   id: 'calendar',
  //   title: 'Calendar',
  //   type: 'item',
  //   icon: <Visibility />,
  //   permissions: ['admin', 'editor'],
  //   navLink: '/login',
  // },
  // {
  //   id: 'eCommerce',
  //   title: 'Ecommerce',
  //   type: 'collapse',
  //   icon: <PhoneAndroid />,
  //   children: [
  //     {
  //       id: 'shop',
  //       title: 'Shop',
  //       type: 'item',
  //       permissions: ['admin', 'editor'],
  //       navLink: '/login',
  //     },
  //     {
  //       id: 'detail',
  //       title: 'Product Detail',
  //       type: 'item',
  //       permissions: ['admin', 'editor'],
  //       navLink: '/login',
  //     },
  //   ],
  // },
  // {
  //   id: 'calendar',
  //   title: 'Calendar',
  //   type: 'item',
  //   icon: <Visibility />,
  //   permissions: ['admin', 'editor'],
  //   navLink: '/login',
  // },
];
