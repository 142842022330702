import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product, AppConfig } from 'react-morel-auth';
import { FilterType } from '../../../hooks/utils/filter/types';

export type ProductFilter = { property: string; values: Array<string>; type: FilterType; field: string | undefined };

export type ProductsConfig = {
  page?: number;
  limit?: number;
  sort?: Array<string> | null;
  filters?: Array<ProductFilter>;
  searchQuery?: string | null;
  productType?: 'plv' | 'frame';
  fields?: Array<keyof Product>;
  config?: AppConfig;
  clientIdForOffer?: string;
};

type ProductParamsOptions = { page: number; limit: number };
export interface ProductParamsState {
  productFilters: Array<ProductFilter>;
  searchQuery: string;
  urlSearchParams: URLSearchParams;
  options: ProductParamsOptions;
}

const initialState: ProductParamsState = {
  productFilters: [],
  searchQuery: '',
  urlSearchParams: new URLSearchParams(),
  options: {
    page: 1,
    limit: 20,
  },
};

export const productParamsSlice = createSlice({
  name: 'productParams',
  initialState,
  reducers: {
    changeFilter: (
      state,
      action: PayloadAction<{ property: string; type: FilterType; field: string | undefined; values: Array<string> }>,
    ) => {
      const filterIndex = state.productFilters.findIndex(filter => filter.property === action.payload.property);
      if (filterIndex === -1) {
        state.productFilters.push({
          property: action.payload.property,
          values: action.payload.values,
          type: action.payload.type,
          field: action.payload.field,
        });
      }
      state.productFilters[filterIndex] = { ...state.productFilters[filterIndex], values: action.payload.values };
    },
    changeFilterFromSearchParams: (state, action: PayloadAction<{ params: string }>) => {
      if (action.payload.params !== JSON.stringify(state.productFilters)) {
        state.productFilters = JSON.parse(action.payload.params);
      }
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
      state.urlSearchParams.append('search', action.payload);
    },
    setOptions: (state, action: PayloadAction<ProductParamsOptions>) => {
      const options = action.payload;
      state.options = { ...state.options, ...options };
      Object.keys(options).forEach(optionKey => {
        state.urlSearchParams.append(optionKey, options[optionKey as keyof ProductParamsOptions].toString());
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeFilter, changeFilterFromSearchParams, setSearchQuery } = productParamsSlice.actions;

export default productParamsSlice.reducer;
