import React, { useEffect, useState } from 'react';
import { NavigationEntry } from '../../routes';
import { useTheme } from '@mui/styles';
import { ListItemButton, ListItemIcon, ListItemText, Theme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useDrawerSize } from '../../context/drawerSizeContext';

type DrawerEntryProps = {
  item: NavigationEntry;
};

const StyledListItemButton = styled(ListItemButton)`
  overflow: hidden;
  white-space: nowrap;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  ${({ selected }) =>
    !selected &&
    `
    &:hover {
      margin-left: 10px;
    },
  `}
`;

const DrawerEntry: React.FC<DrawerEntryProps> = ({ item }) => {
  const { title, icon } = item;
  const { drawerType } = useDrawerSize();

  const location = useLocation();
  const navigate = useNavigate();
  const theme: Theme = useTheme();

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (item.navLink !== undefined && location.pathname.includes(item.navLink)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [item.navLink, location.pathname]);

  return (
    <StyledListItemButton
      onClick={() => (item.navLink ? navigate(item.navLink) : null)}
      selected={selected}
      style={{
        paddingLeft: drawerType === 'hide' ? 0 : 16,
        paddingRight: drawerType === 'hide' ? 0 : 16,
        backgroundColor: selected ? theme.palette.action.selected : undefined,
        color: selected ? theme.palette.primary.contrastText : undefined,
      }}
    >
      <ListItemIcon style={{ color: selected ? theme.palette.primary.contrastText : undefined }}>{icon}</ListItemIcon>
      <ListItemText primary={title} />
    </StyledListItemButton>
  );
};

export default DrawerEntry;
