import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import { userReducer, UserState } from './reducer';
import { setEndRequestLogin, setLoginError, setLogout, setRequestLogin, setUser, UserAction } from './actions';
import { authenticateFromApp, getToken, getUser, logout } from 'react-morel-auth';
import { User } from './types';

const UserContext = React.createContext<
  | {
      userState: UserState;
      dispatchUser: (action: UserAction) => void;
      signIn: (login: string, password: string, serviceCode: string, callback: () => void) => void;
      logout: () => void;
      hasPermission: (permissions: Array<string>) => boolean;
    }
  | undefined
>(undefined);

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [userState, dispatchUser] = useReducer(userReducer, {
    user: null,
    loading: false,
    isLogged: false,
    error: null,
  });

  const signIn = async (login: string, password: string, serviceCode: string, callback: () => void) => {
    dispatchUser(setRequestLogin());
    try {
      await authenticateFromApp(
        process.env.REACT_APP_APIAUTH_USER || '',
        process.env.REACT_APP_APIAUTH_KEY || '',
        login,
        password,
      );

      const user = await getUser();
      if (user) {
        dispatchUser(setUser(user.user));
        callback();
      }
    } catch (e) {
      dispatchUser(setLoginError(e));
    }
  };

  const logoutUser = () => {
    logout();
    dispatchUser(setLogout());
  };

  const hasPermission = useCallback(
    (permission: Array<string> | undefined) => {
      if (!permission || permission.length === 0) {
        return true;
      }
      if (!userState.user) {
        return false;
      }
      return userState.user.roles.some(r => permission.includes(r.replace('*', '')));
    },
    [userState.user],
  );

  const value = { userState, dispatchUser, signIn, logout: logoutUser, hasPermission };

  const refreshLogUser = useCallback(async () => {
    try {
      if (await getToken()) {
        dispatchUser(setRequestLogin());
        const response = await getUser();
        if (response) {
          dispatchUser(setUser(response.user));
        } else {
          dispatchUser(setLoginError(new Error('User not found')));
        }
      } else {
        dispatchUser(setEndRequestLogin());
      }
    } catch (e) {
      dispatchUser(setLoginError(new Error(e.message)));
    }
  }, []);

  useEffect(() => {
    refreshLogUser();
  }, [refreshLogUser]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
};

export { UserProvider, useUser };
