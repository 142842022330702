import { User } from './types';
import { UserAction } from './actions';

export type UserState = {
  user: User | null;
  loading: boolean;
  isLogged: boolean;
  error: Error | null;
};

export const userReducer = (state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case 'request login':
      return {
        ...state,
        loading: false,
        error: null,
        isLogged: false,
      };
    case 'end request login':
      return {
        ...state,
        loading: false,
      };
    case 'login user': {
      return {
        ...state,
        user: action.payload.user,
        isLogged: true,
        loading: false,
        error: null,
      };
    }
    case 'logout user':
      return {
        ...state,
        user: null,
        error: null,
        isLogged: false,
        loading: false,
      };
    case 'login error':
      return {
        ...state,
        user: null,
        error: action.payload,
        loading: false,
        isLogged: false,
      };
    default:
      return state;
  }
};
