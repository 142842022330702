import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

type LoaderProps = {
  display: boolean;
  spinnerStyle?: React.CSSProperties;
  size?: number;
  displayText?: boolean;
  textStyle?: React.CSSProperties;
};

const StyledContainer = styled('div')`
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  //margin: 5px;
`;

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginLeft: 20,
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const Loader: React.FC<LoaderProps> = ({ display, textStyle, spinnerStyle, size, displayText }) => {
  const { t } = useTranslation(['common']);
  if (display) {
    return (
      <StyledContainer>
        <StyledCircularProgress size={size} style={spinnerStyle} />
        {(displayText === undefined || displayText) && (
          <StyledTypography variant="h5" color="textPrimary" style={textStyle}>
            {t('loading')}
          </StyledTypography>
        )}
      </StyledContainer>
    );
  }
  return null;
};

export default Loader;
