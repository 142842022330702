import React, { useEffect, useRef } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Avatar, Button, Collapse, ListItemButton, ListItemText, Theme, Typography } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../context/userContext';

type UserAccountProps = {};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountButton: {
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      color: theme.palette.primary.contrastText,
    },
    userTypo: {
      marginRight: 20,
    },
    logoutItem: {
      color: theme.palette.error.main,
    },
    collapse: {
      position: 'absolute',
      top: 64,
      backgroundColor: 'white',
      color: 'black',
      width: 165,
    },
  }),
);

const UserAccount: React.FC<UserAccountProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { userState, logout } = useUser();

  const [showMenu, setShowMenu] = React.useState(false);

  const menuRef: any = useRef();

  /**
   * Handle click outside collapse
   */
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (showMenu && menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef, showMenu]);

  return (
    <div ref={menuRef}>
      <Button className={classes.accountButton} onClick={() => setShowMenu(!showMenu)}>
        <Typography sx={{ display: { xs: 'none', sm: 'block' } }} className={classes.userTypo}>
          {userState.user?.username}
        </Typography>
        <Avatar>
          <AccountCircle />
        </Avatar>
      </Button>
      <Collapse
        style={{ position: 'absolute', top: 63, right: 20, backgroundColor: 'white', color: 'black' }}
        in={showMenu}
        timeout={'auto'}
      >
        {/* <ListItemButton> */}
        {/*  <ListItemText>{t('myAccount')}</ListItemText> */}
        {/* </ListItemButton> */}
        <ListItemButton onClick={logout}>
          <ListItemText className={classes.logoutItem}>{t('logout')}</ListItemText>
        </ListItemButton>
      </Collapse>
    </div>
  );
};

export default UserAccount;
