import { User } from './types';

// region Actions Types
export type LoginAction = {
  type: 'login user';
  payload: { user: User };
};
export type RequestLoginAction = {
  type: 'request login';
};
export type LoginErrorAction = {
  type: 'login error';
  payload: Error | null;
};
export type LogoutAction = {
  type: 'logout user';
};
export type EndRequestLoginAction = {
  type: 'end request login';
};

export type UserAction = LoginAction | RequestLoginAction | LoginErrorAction | LogoutAction | EndRequestLoginAction;

// endregion

export const setRequestLogin = (): RequestLoginAction => ({
  type: 'request login',
});

export const setUser = (user: User): LoginAction => ({
  type: 'login user',
  payload: { user },
});

export const setLoginError = (error: Error): LoginErrorAction => ({
  type: 'login error',
  payload: error,
});

export const setEndRequestLogin = (): EndRequestLoginAction => ({
  type: 'end request login',
});

export const setLogout = (): LogoutAction => ({
  type: 'logout user',
});
