import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MarketingFieldStatus, ProductMarketing } from 'react-morel-auth';
import { extendedApi } from '../../network/QGApi/builders/marketingFieldsEndpoints';
import _ from 'lodash';

export type ProductMarketingState = ProductMarketing & {
  status: MarketingFieldStatus;
  initialId: string;
  error?: string | null;
};

export interface ProductMarketingFieldsState {
  fields: Array<ProductMarketingState>;
  clipboardFields: Array<ProductMarketingState>;
  selectedFields: Array<ProductMarketingState>;
}

const initialState: ProductMarketingFieldsState = {
  fields: [],
  clipboardFields: [],
  selectedFields: [],
};

const productMarketingSlice = createSlice({
  name: 'productMarketing',
  initialState: initialState,
  reducers: {
    createMarketingField: (state, action: PayloadAction<string>) => {
      state.fields.push({
        id: '',
        productId: action.payload,
        initialId: '',
        value: {},
        status: MarketingFieldStatus.NEW,
      });
    },
    deleteMarketingField: (state, action: PayloadAction<string>) => {
      const index = state.fields.findIndex(field => field.id === action.payload);
      if (index !== -1) {
        state.fields[index].status = MarketingFieldStatus.DELETED;
        // si l'item est dans le tableau des selection, on le retire
        const fieldIndexInSelected = state.selectedFields.findIndex(field => field.id === state.fields[index].id);
        if (fieldIndexInSelected !== -1) {
          state.selectedFields.splice(fieldIndexInSelected, 1);
        }
      }
    },
    updateMarketingField: (
      state,
      action: PayloadAction<Partial<ProductMarketingState> & Pick<ProductMarketing, 'id'> & { previousId: string }>,
    ) => {
      const { previousId, ...rest } = action.payload;
      const index = state.fields.findIndex(field => field.id === previousId);
      if (index !== -1) {
        state.fields[index] = { ...state.fields[index], ...rest, error: rest.error ? rest.error : null };
        if (state.fields[index].initialId) {
          state.fields[index].status = MarketingFieldStatus.CHANGED;
        } else {
          state.fields[index].status = MarketingFieldStatus.NEW;
        }
      }
    },
    errorMarketingField: (state, action: PayloadAction<{ fieldId: string; error: string }>) => {
      const { fieldId, error } = action.payload;
      const index = state.fields.findIndex(field => field.id === fieldId);
      if (index !== -1) {
        state.fields[index].error = error || null;
        state.fields[index].status = MarketingFieldStatus.ERROR;
      }
    },
    createMarketingFieldLng: (state, action: PayloadAction<{ fieldId: string; lng: string }>) => {
      const { fieldId, lng } = action.payload;
      const index = state.fields.findIndex(field => field.id === fieldId);
      if (index !== -1) {
        state.fields[index].value = { ...state.fields[index].value, [lng]: '' };
        if (state.fields[index].status !== MarketingFieldStatus.NEW) {
          state.fields[index].status = MarketingFieldStatus.CHANGED;
        }
      }
    },
    updateMarketingFieldLng: (
      state,
      action: PayloadAction<{ fieldId: string; previousLngKey: string; value: string }>,
    ) => {
      const { fieldId, value, previousLngKey } = action.payload;
      const index = state.fields.findIndex(field => field.id === fieldId);
      if (index !== -1) {
        if (Object.keys(state.fields[index].value).every(key => key !== '')) {
          if (state.fields[index].value[previousLngKey] !== value) {
            state.fields[index].value[previousLngKey] = value;
            state.fields[index].error = null;
            if (state.fields[index].initialId) {
              state.fields[index].status = MarketingFieldStatus.CHANGED;
            } else {
              state.fields[index].status = MarketingFieldStatus.NEW;
            }
          }
        }
      }
    },
    deleteMarketingFieldLng: (state, action: PayloadAction<{ fieldId: string; lngKey: string }>) => {
      const { fieldId, lngKey } = action.payload;
      const index = state.fields.findIndex(field => field.id === fieldId);
      if (index !== -1) {
        delete state.fields[index].value[lngKey];
        if (state.fields[index].initialId) {
          state.fields[index].status = MarketingFieldStatus.CHANGED;
        } else {
          state.fields[index].status = MarketingFieldStatus.NEW;
        }
      }
    },
    selectMarketingField: (state, action: PayloadAction<string>) => {
      const fieldId = action.payload;
      const selectedFieldIndex = state.selectedFields.findIndex(selectedField => selectedField.id === fieldId);
      if (selectedFieldIndex !== -1) {
        state.selectedFields = state.selectedFields.filter(selectedField => selectedField.id !== fieldId);
      } else {
        const fieldIndex = state.fields.findIndex(field => field.id === fieldId);
        state.selectedFields.push(state.fields[fieldIndex]);
      }
    },
    selectAllMarketingFields: state => {
      if (state.selectedFields.length === state.fields.length) {
        state.selectedFields = [];
      } else {
        state.selectedFields = state.fields;
      }
    },
    deleteSelectedMarketingField: state => {
      state.fields.forEach(field => {
        if (state.selectedFields.find(selectedField => selectedField.id === field.id)) {
          field.status = MarketingFieldStatus.DELETED;
        }
      });
      state.selectedFields = [];
    },
    duplicateSelectedMarketingField: state => {
      state.selectedFields.forEach(selectedField => {
        let key = selectedField.id;
        while (state.fields.some(field => field.id === key)) {
          const match = key.match(/^(.*)-([0-9]+)$/);
          if (match === null) {
            key = `${selectedField.id}-1`;
          } else {
            key = `${match[1]}-${parseInt(match[2]) + 1}`;
          }
        }

        state.fields.push({
          ...selectedField,
          status: MarketingFieldStatus.NEW,
          initialId: '',
          id: key,
        });
      });

      state.selectedFields = [];
    },
    pasteMarketingFields: (state, action: PayloadAction<string>) => {
      // need product id to paste
      const productId = action.payload;
      const currentFieldState = [...state.fields];
      state.clipboardFields.forEach(clipField => {
        const previousVersionIndex = currentFieldState.findIndex(field => field.id === clipField.id);
        if (previousVersionIndex === -1) {
          state.fields.push({
            ...clipField,
            status: MarketingFieldStatus.NEW,
            initialId: '',
            productId,
          });
        } else {
          state.fields[previousVersionIndex] = {
            ..._.cloneDeep(clipField),
            status: MarketingFieldStatus.CHANGED,
            productId,
          };
        }
      });
      state.selectedFields = [];
    },
    copyMarketingFieldsToClipBoard: state => {
      state.clipboardFields = state.selectedFields;
      state.selectedFields = [];
    },
    copyMarketingFieldToClipBoard: (state, action: PayloadAction<ProductMarketingState>) => {
      state.clipboardFields = [action.payload];
    },
  },
  extraReducers: builder => {
    builder.addMatcher(extendedApi.endpoints.getMarketingFields.matchFulfilled, (state, { payload }) => {
      state.fields = payload.map(field => ({
        ...field,
        status: MarketingFieldStatus.UP_TO_DATE,
        initialId: field.id,
      }));
    });
  },
});

export const {
  createMarketingField,
  deleteMarketingField,
  updateMarketingField,
  createMarketingFieldLng,
  updateMarketingFieldLng,
  errorMarketingField,
  deleteMarketingFieldLng,
  selectMarketingField,
  selectAllMarketingFields,
  copyMarketingFieldToClipBoard,
  copyMarketingFieldsToClipBoard,
  pasteMarketingFields,
  deleteSelectedMarketingField,
  duplicateSelectedMarketingField,
} = productMarketingSlice.actions;

export default productMarketingSlice.reducer;
