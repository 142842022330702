import i18next from 'i18next';
import common_en from './en.json';
import common_fr from './fr.json';

export const initI18n = () => {
  i18next.init({
    interpolation: { escapeValue: false },
    lng: 'fr',
    resources: {
      en: {
        translation: common_en,
      },
      fr: {
        translation: common_fr,
      },
    },
  });
};
