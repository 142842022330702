import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useUser } from '../context/userContext';
import { routesURL } from './routesURL';
import Lottie from 'react-lottie';
import { Typography } from '@mui/material';
import accessDenied from '../lottie/accessDenied.json';

type PrivateRoutesProps = {
  children: React.ReactNode;
  requiredRoles?: Array<string>;
};

const accessDeniedOptions = {
  autoplay: true,
  animationData: accessDenied,
  loop: true,
};

const ProtectedRoutes: React.FC<PrivateRoutesProps> = ({ children, requiredRoles }) => {
  const { userState, hasPermission } = useUser();
  const location = useLocation();
  if (!userState.isLogged) {
    if (userState.loading) {
      return <div style={{ width: '100%', height: '100vh' }} />;
    }
    return <Navigate to={routesURL.login} state={{ from: location }} />;
  }

  if (requiredRoles && userState.user && !hasPermission(requiredRoles)) {
    return (
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translateY(-75%) translateX(-50%)' }}>
        <Lottie options={accessDeniedOptions} height={400} width={400} />
        <Typography variant={'h4'}>Sorry, but you are not allowed to view this page</Typography>
      </div>
    );
  }

  return <div>{children}</div>;
};

export default ProtectedRoutes;
