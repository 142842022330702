import { FetchArgs, FetchBaseQueryError, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import { getAuthApiURL, getToken, setToken, setRefreshToken, getRefreshToken } from 'react-morel-auth';

export const refreshAuthData = async (oldToken: string, refreshToken: string) => {
  // On se connecte à l'API avec les infos de l'app
  // const refreshToken = getUserRefreshToken();
  const response = await fetch(getAuthApiURL() + '/token/refresh', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + oldToken,
    },
    body: JSON.stringify({ refresh_token: refreshToken }),
  });

  const responseJson = await response.json();
  if (responseJson.token) {
    setToken(responseJson.token);
    setRefreshToken(responseJson.refresh_token);

    return;
  }

  throw new Error('Failed to refresh tokens');
};

export const callApi: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, { baseUrl: string }> = async (
  args,
  api,
  extraOptions,
) => {
  let userToken = await getToken();
  const baseQuery = fetchBaseQuery({
    baseUrl: extraOptions.baseUrl,
    prepareHeaders: headers => {
      headers.set('Content-Type', 'application/json');
      headers.set('authorization', `Bearer ${userToken}`);
      headers.set('Accept', 'application/ld+json');

      return headers;
    },
  });
  let result = await baseQuery(args, api, extraOptions);

  const errorMessage = (result.error?.data as any)?.message;
  if (result?.meta?.response?.status === 401) {
    if (
      result?.meta?.response?.statusText === 'Expired JWT Token' ||
      result?.meta?.response?.statusText === 'Token is expired' ||
      result?.meta?.response?.statusText === 'Unauthorized'
    ) {
      try {
        await refreshAuthData(await getToken(), await getRefreshToken());
      } catch (e) {
        throw new Error(e);
      }

      userToken = getToken();
      const baseQueryRetry = fetchBaseQuery({
        baseUrl: extraOptions.baseUrl,
        prepareHeaders: headers => {
          headers.set('Content-Type', 'application/json');
          headers.set('authorization', `Bearer ${userToken}`);
          headers.set('Accept', 'application/ld+json');
          headers.set('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS');

          return headers;
        },
      });
      result = await baseQueryRetry(args, api, extraOptions);
    }
  }
  return result;
};

// eyJhbGciOiJSUzUxMiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NTYzMjQ4NDMsImlkZW50aXR5S2V5IjoicGEudGF2ZWwiLCJvcmlnX2lhdCI6MTY1NjMyMTI0MywidXNlclR5cGUiOiJ1c2VyIiwidXNpbmdBcHAiOiJjb20ubW9yZWxvbmxpbmUuYmFja29mZmljZS10ZXN0In0.IgkfnVgIO1jLGzziYboFHQqr3yQs--1v-EEGI6aJ-RVhMsAYOC45sTtcF6a4043ooXbR2cWtUZisFSczp47gUNez-TrHHSui94XAYnm9acxLpSbnyVT14D5ezTf-r53bWdIXqpKhZQqHjBUe3A16zcrF7NA80LUvEGAP8QjfP2GGLHRnPGFaK1kuV5hu5a0Mr18frxwN5T0hmgLYeXNJiPByYK6bSUM-Y63V0w566rs2CDToV5OW4vFY5tLIHDheRqDVuYSbfhjnsVLRvL7isnPxTN88LEBERU7ATwQ1vzkxvkRaaYdCAmzVaUFJw33aYqLDOkCQJiRcx5D0bIC7jO0FB5eSKNNbGwrk2Z25bPGhP07ITW8wxnfNCKhhTMgpMsX6fCHjX0M4ZtqYhp2JjnNe1XnELq3oRZ3n6vq_IrmO3xBMjuGQEF8_Bfh0lAJJVu4p5KoWhVkAht-Mun0Sk3biAbTHoc_C4gA9GKHGvPZLSRzMMAjCX2SXQUXEHpMU3kXDiiWB0yX_TYUsF5EKyOm_hfTLgBheAPVFRvmxbwmFqmYD86M3uLlUiRPPU1qJkOaLL6eRd7ACJb2-O7Ky3f6xSeqKiQxntdEuY0f8yAukLofdmdDCCP2usgy1gW8NJwnYaWnAmIDjkLqwHyxTP1CiaoqiyY0pPOY7VXEiCZQ
