import React, { createContext, useContext, useState } from 'react';

const initialState = {
  dark: false,
  toggleDark: () => {},
};

const DarkModeContext = createContext<{ dark: boolean; toggleDark: () => void }>(initialState);

const DarkModeProvider = ({ children }: { children: React.ReactNode }) => {
  const [dark, setDark] = useState(JSON.parse(localStorage.getItem(`dark_mode`) || 'false') || false);

  const toggleDark = () => {
    localStorage.setItem(`dark_mode`, JSON.stringify(!dark));
    setDark(!dark);
  };

  return <DarkModeContext.Provider value={{ dark, toggleDark }}>{children}</DarkModeContext.Provider>;
};

const useDarkTheme = () => {
  const context = useContext(DarkModeContext);
  if (context === undefined) {
    throw new Error('useDarkTheme must be used within a DarkModeProvider');
  }

  return context;
};

export { DarkModeProvider, useDarkTheme };
