import React from 'react';
import Drawer from '../screen/Drawer';
import AppBar from '../screen/AppBar';
import { appBarHeight } from '../styles/theme';
import { Outlet } from 'react-router-dom';
import LoadingFallback from './LoadingFallback';
import { useDrawerSize } from '../context/drawerSizeContext';

type NavigationLayoutProps = {};

const NavigationLayout: React.FC<NavigationLayoutProps> = () => {
  const { drawerSize, changeDrawerSize } = useDrawerSize();

  return (
    <>
      <AppBar onExpandDrawer={changeDrawerSize} />
      <Drawer drawerSize={drawerSize} />
      <main style={{ marginLeft: drawerSize, marginTop: appBarHeight }}>
        <React.Suspense fallback={<LoadingFallback />}>
          <Outlet />
        </React.Suspense>
      </main>
    </>
  );
};

export default NavigationLayout;
