import React, { ReactNode, useMemo } from 'react';
import { useUser } from '../context/userContext';

type ProtectedComponentProps = {
  children: ReactNode;
  requiredPermissions: Array<string> | undefined;
};

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({ children, requiredPermissions }) => {
  const { userState, hasPermission } = useUser();

  const isPermissionGranted = useMemo(() => {
    if (userState.user && requiredPermissions) {
      return hasPermission(requiredPermissions);
    }
    return false;
  }, [hasPermission, requiredPermissions, userState.user]);

  if (isPermissionGranted) {
    return <>{children}</>;
  }
  return null;
};

export default ProtectedComponent;
