import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { initI18n } from './translations/initI18n';
import { DarkModeProvider, useDarkTheme } from './context/darkModeContext/darkModeContext';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { darkTheme, lightTheme } from './styles';
import { CssBaseline } from '@mui/material';
import { UserProvider } from './context/userContext';
import { DrawerSizeProvider } from './context/drawerSizeContext';
import { Provider } from 'react-redux';
import { rootStore } from './store';

initI18n();

const DarkThemeContainer = () => {
  const { dark } = useDarkTheme();

  return (
    <ThemeProvider theme={dark ? darkTheme : lightTheme}>
      <CssBaseline enableColorScheme />
      <UserProvider>
        <Provider store={rootStore}>
          <App />
        </Provider>
      </UserProvider>
    </ThemeProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <StyledEngineProvider injectFirst>
        <DarkModeProvider>
          <DrawerSizeProvider>
            <DarkThemeContainer />
          </DrawerSizeProvider>
        </DarkModeProvider>
      </StyledEngineProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
