import React, { useMemo, useState } from 'react';
import { NavigationEntry } from '../../routes';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { createStyles, makeStyles } from '@mui/styles';
import DrawerEntry from './DrawerEntry';
import { useDrawerSize } from '../../context/drawerSizeContext';

type CollapseDrawerEntryProps = {
  item: NavigationEntry;
};

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      overflow: 'hidden',
      transition: '0.4s cubic-bezier(0.25, 0.8, 0.25, 1)',
      '&:hover': {
        marginLeft: 10,
      },
    },
  }),
);

const CollapseDrawerEntry: React.FC<CollapseDrawerEntryProps> = ({ item }) => {
  const { title, icon } = item;
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const { drawerType } = useDrawerSize();

  const paddingHorizontal = useMemo(() => (drawerType === 'hide' ? 0 : 16), [drawerType]);

  return (
    <>
      <ListItemButton
        className={classes.container}
        style={{ paddingLeft: paddingHorizontal, paddingRight: paddingHorizontal }}
        onClick={() => setOpen(!open)}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      {drawerType === 'full' && (
        <Collapse in={open && drawerType === 'full'} timeout={'auto'}>
          <List component="div" disablePadding>
            {item.children && item.children.map(child => <DrawerEntry key={child.id} item={child} />)}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default CollapseDrawerEntry;
