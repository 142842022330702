import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { AppBar, Box, IconButton, Theme, Toolbar, Typography } from '@mui/material';
import { DarkMode, LightMode, Menu as MenuIcon } from '@mui/icons-material';
import UserAccount from './components/UserAccount';
import { useTranslation } from 'react-i18next';
import { appBarHeight } from '../../styles/theme';
import { useDarkTheme } from '../../context/darkModeContext/darkModeContext';

type AppMainBarProps = {
  onExpandDrawer: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: 60,
    },
    toolbar: {
      paddingLeft: 15,
      height: appBarHeight,
    },
    icon: {
      fill: theme.palette.primary.contrastText,
    },
    root: {
      color: theme.palette.primary.contrastText,
    },
  }),
);

const AppMainBar: React.FC<AppMainBarProps> = ({ onExpandDrawer }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { dark, toggleDark } = useDarkTheme();

  return (
    <AppBar position={'fixed'} color={'primary'}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          sx={{ mr: 2 }}
          onClick={onExpandDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ display: { xs: 'none', sm: 'block' } }}>
          {t('backoffice')}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: 'flex', md: 'flex' }, alignItems: 'center', justifyContent: 'center' }}>
          {!dark ? (
            <IconButton onClick={toggleDark}>
              <DarkMode color={'secondary'} />
            </IconButton>
          ) : (
            <IconButton onClick={toggleDark}>
              <LightMode />
            </IconButton>
          )}

          <UserAccount />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AppMainBar;
