export const routesURL = {
  login: '/login',
  dashboard: '/dashboard',

  partnerUsers: '/partner-users',
  createPartnerUser: '/partner-users/create',
  partnerUserDetail: '/partner-users',

  appUsers: '/app-users',
  createAppUser: '/app-users/create',
  appUserDetail: '/app-users',

  userServices: '/user-services',
  createUsersService: '/user-services/create',
  userServiceDetail: '/user-services',

  apps: '/applications',
  createApp: '/applications/create',
  appDetail: '/applications',

  partners: '/partners',
  createPartner: '/partners/create',
  partnerDetail: '/partners',

  partnerServicesDetail: '/partner-partner-services',
  partnerServices: '/partner-services',
  createPartnerServices: '/partner-services/create',

  services: '/services',
  createService: '/services/create',
  serviceDetail: '/services',

  hosts: '/hosts',
  createHost: '/host/create',
  hostDetail: '/hosts',

  products: '/products',
  marketingProducts: '/marketing-products',
  dynamicMessage: '/dynamicMessage',

  jobs: '/jobs',
};
