import { createSlice } from '@reduxjs/toolkit';
import { ProductMarketing } from 'react-morel-auth';

export interface ProductMarketingFieldsState {
  fields: Array<ProductMarketing>;
  clipboardFields: Array<ProductMarketing>;
}

const initialState: ProductMarketingFieldsState = {
  fields: [],
  clipboardFields: [],
};

export const productMarketingFieldsSlice = createSlice({
  name: 'productMarketingFields',
  initialState,
  reducers: {
    // setMarketingFields: (state, action: PayloadAction<Array<MarketingField>>) => {
    //   state.fields = action.payload;
    // },
    // addMarketingFieldTranslation: (state, action: PayloadAction<{ fieldIndex: number }>) => {
    //   state.fields[action.payload.fieldIndex].translations.push({
    //     lng: '',
    //     value: '',
    //     status: MarketingFieldStatus.CHANGED,
    //   });
    // },
    // addMarketingField: state => {
    //   state.fields.push({
    //     title: '',
    //     id: '',
    //     translations: [{ lng: '', value: '', status: MarketingFieldStatus.NEW }],
    //     status: MarketingFieldStatus.NEW,
    //     selected: false,
    //   });
    // },
    // deleteMarketingFieldTranslation: (
    //   state,
    //   action: PayloadAction<{ fieldIndex: number; translationIndex: number }>,
    // ) => {
    //   state.fields[action.payload.fieldIndex].translations.splice(action.payload.translationIndex, 1);
    // },
    // deleteMarketingField: (state, action: PayloadAction<{ fieldIndex: number }>) => {
    //   state.fields[action.payload.fieldIndex].status = MarketingFieldStatus.DELETED;
    // },
    // changeMarketingFieldTitle: (state, action: PayloadAction<{ fieldIndex: number; newTitle: string }>) => {
    //   state.fields[action.payload.fieldIndex].title = action.payload.newTitle;
    //   if (state.fields[action.payload.fieldIndex].status !== MarketingFieldStatus.NEW) {
    //     state.fields[action.payload.fieldIndex].status = MarketingFieldStatus.CHANGED;
    //   }
    // },
    // changeMarketingFieldTranslation: (
    //   state,
    //   action: PayloadAction<{ fieldIndex: number; lngIndex: number; translation: MarketingTranslation }>,
    // ) => {
    //   const { fieldIndex, lngIndex, translation } = action.payload;
    //   state.fields[fieldIndex].translations[lngIndex] = { ...translation, status: MarketingFieldStatus.CHANGED };
    //   if (state.fields[fieldIndex].status !== MarketingFieldStatus.NEW) {
    //     state.fields[fieldIndex].status = MarketingFieldStatus.CHANGED;
    //   }
    // },
    // selectMarketingField: (state, action: PayloadAction<{ fieldIndex: number; selected?: boolean }>) => {
    //   if (action.payload.selected) {
    //     state.fields[action.payload.fieldIndex].selected = action.payload.selected;
    //   } else {
    //     state.fields[action.payload.fieldIndex].selected = !state.fields[action.payload.fieldIndex].selected;
    //   }
    // },
    // selectAllMarketingFields: (state, action: PayloadAction<{ selected?: boolean }>) => {
    //   const { selected } = action.payload;
    //   if (selected) {
    //     state.fields.map(field => (field.selected = selected));
    //   } else {
    //     if (state.fields.every(field => field.selected)) {
    //       state.fields.map(field => (field.selected = false));
    //     } else {
    //       state.fields.map(field => (field.selected = true));
    //     }
    //   }
    // },
    // duplicateSelectedMarketingField: state => {
    //   state.fields = [...state.fields, ...state.fields.filter(field => field.selected)];
    // },
    // deleteSelectedMarketingField: state => {
    //   state.fields = state.fields.map(field => {
    //     if (field.selected) {
    //       field.status = MarketingFieldStatus.DELETED;
    //     }
    //     return field;
    //   });
    // },
    // pasteMarketingField: state => {
    //   const currentFieldState = [...state.fields];
    //   state.clipboardFields.forEach(clipField => {
    //     const previousVersionIndex = currentFieldState.findIndex(field => field.id === clipField.id);
    //     if (previousVersionIndex === -1) {
    //       state.fields.push(clipField);
    //     } else {
    //       state.fields[previousVersionIndex] = clipField;
    //     }
    //   });
    //
    //   // state.clipboardFields.forEach(clipField => {
    //   //   const previousVersionIndex = state.fields.findIndex(field => field.title === clipField.title);
    //   // });
    //   // state.fields = state.clipboardFields;
    // },
    // copyMarketingFieldsToClipBoard: (state, action: PayloadAction<{ fields: Array<ProductMarketing> }>) => {
    //   state.clipboardFields = action.payload.fields;
    // },
  },
});

export default productMarketingFieldsSlice.reducer;
