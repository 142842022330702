import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes/Routes';
import Loader from './component/Loader';
import { useInitMorelAuth } from 'react-morel-auth';

const initParams = {
  storage: {
    setItem: (key: string, value: string) => {
      localStorage.setItem(key, value);
    },
    getItem: (key: string) => {
      return new Promise<string>(resolve => resolve(localStorage.getItem(key) || ''));
    },
    removeItem: (key: string) => localStorage.removeItem(key),
  },
  apiUrls: {
    authApiUrl: process.env.REACT_APP_APIAUTH_URL || '',
    qgApiUrl: process.env.REACT_APP_APIQG_URL || '',
    schedulerApiUrl: process.env.REACT_APP_APISCHEDULER_URL || '',
    m3ApiUrl: process.env.REACT_APP_APIAUTH_M3_URL || '',
  },
  serviceCode: process.env.REACT_APP_SERVICE_CODE || '',
};

function App() {
  const { loadingInitMorelAuth, errorInitMorelAuth } = useInitMorelAuth(initParams);

  return (
    <div>
      {!loadingInitMorelAuth && !errorInitMorelAuth && (
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      )}
      {loadingInitMorelAuth && <Loader display={true} />}
    </div>
  );
}

export default App;
