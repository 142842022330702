import React from 'react';
import { Collapse, ListItemButton, ListItemText } from '@mui/material';
import { NavigationEntry } from '../../routes';
import { styled } from '@mui/material/styles';
import { useDrawerSize } from '../../context/drawerSizeContext';

type SectionTitleDrawerProps = {
  item: NavigationEntry;
};

const StyledItemText = styled(ListItemText)`
  opacity: 0.5;
`;

const SectionTitleDrawer: React.FC<SectionTitleDrawerProps> = ({ item }) => {
  const { drawerType } = useDrawerSize();

  const { title } = item;
  return (
    <Collapse in={drawerType === 'full'} timeout={'auto'} unmountOnExit>
      <ListItemButton>
        <StyledItemText primary={title} />
      </ListItemButton>
    </Collapse>
  );
};

export default SectionTitleDrawer;
