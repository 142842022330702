import React from 'react';
import { navigationConfig } from '../../routes';
import { createStyles, makeStyles } from '@mui/styles';
import CollapseDrawerEntry from './CollapseDrawerEntry';
import DrawerEntry from './DrawerEntry';
import SectionTitleDrawer from './SectionTitleDrawer';
import { Paper } from '@mui/material';
import ProtectedComponent from '../../component/ProtectedComponent';
import { useUser } from '../../context/userContext';

type DrawerProps = {
  drawerSize: number;
};

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100vh',
      transition: '0.4s cubic-bezier(0.25, 0.8, 0.25, 1)',
      position: 'fixed',
      paddingTop: 20,
    },
  }),
);

const Drawer: React.FC<DrawerProps> = ({ drawerSize }) => {
  const classes = useStyles();
  const { hasPermission } = useUser();

  const showGroup = (group: string) => {
    const permissions: Array<string> = navigationConfig
      .filter(config => config.group === group && config.type !== 'groupHeader')
      .reduce(
        (previousValue: Array<string>, currentValue) => [...previousValue, ...(currentValue.permissions || [])],
        [],
      );

    return hasPermission(permissions);
  };

  return (
    <Paper
      className={classes.container}
      style={{
        width: drawerSize,
      }}
    >
      {navigationConfig.map((configItem, index) => {
        return (
          <div key={index}>
            {showGroup(configItem.group) && configItem.type === 'groupHeader' && (
              <SectionTitleDrawer item={configItem} />
            )}
            <ProtectedComponent key={`drawer_item_${index}`} requiredPermissions={configItem.permissions}>
              {configItem.type === 'collapse' && <CollapseDrawerEntry item={configItem} />}
              {configItem.type === 'item' && <DrawerEntry item={configItem} />}
            </ProtectedComponent>
          </div>
        );
      })}
    </Paper>
  );
};

export default Drawer;
