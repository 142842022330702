import { createApi } from '@reduxjs/toolkit/query/react';
import { APIDataCollection, ProductMarketing } from 'react-morel-auth';
import { callApi } from '../../callApi';

// Define a service using a base URL and expected endpoints
export const QGApi = createApi({
  reducerPath: 'QGApi',
  baseQuery: (args, api) => callApi(args, api, { baseUrl: process.env.REACT_APP_APIQG_URL || '' }),
  endpoints: () => ({}),
  tagTypes: ['MarketingFields'],
});
