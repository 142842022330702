import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { closeDrawerWidth, fullDrawerWidth, smallDrawerWidth } from '../../styles';

type DrawerType = 'hide' | 'small' | 'full';

type DrawerSize = {
  drawerType: DrawerType;
  changeDrawerSize: () => void;
  drawerSize: number;
};

const initialState: DrawerSize = {
  drawerType: 'full',
  drawerSize: fullDrawerWidth,
  changeDrawerSize: () => {},
};

const DrawerSizeContext = createContext<DrawerSize>(initialState);

const DrawerSizeProvider = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [drawerType, setDrawerType] = useState<DrawerType>(largeScreen ? 'full' : 'hide');
  const [drawerSize, setDrawerSize] = useState(fullDrawerWidth);

  useEffect(() => {
    setDrawerType(largeScreen ? 'full' : 'hide');
  }, [largeScreen]);

  useEffect(() => {
    switch (drawerType) {
      case 'hide':
        setDrawerSize(closeDrawerWidth);
        break;
      case 'small':
        setDrawerSize(smallDrawerWidth);
        break;
      default:
        setDrawerSize(fullDrawerWidth);
    }
  }, [drawerType]);

  const changeDrawerSize = () => {
    if (!largeScreen) {
      setDrawerType(drawerType === 'full' ? 'hide' : 'full');
    } else {
      setDrawerType(drawerType === 'full' ? 'small' : 'full');
    }
  };

  return (
    <DrawerSizeContext.Provider value={{ drawerType, changeDrawerSize, drawerSize }}>
      {children}
    </DrawerSizeContext.Provider>
  );
};

const useDrawerSize = () => {
  const context = useContext(DrawerSizeContext);
  if (context === undefined) {
    throw new Error('useDrawerSize must be used within a DrawerSizeProvider');
  }

  return context;
};

export { DrawerSizeProvider, useDrawerSize };
